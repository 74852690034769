import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Slider from "react-slick"
import uuid from 'uuid'
import ReactBnbGallery from 'react-bnb-gallery'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faUser } from '@fortawesome/free-regular-svg-icons';

import useWindowSize from '../utils/useWindowSize';

import Layout from '../components/Layout'
import SEO from '../components/seo'
import BgImage from "../components/BgImage"
import RelatedRooms from '../components/RelatedRooms'
import BookingForm from '../components/BookingForm';
import ScrollToBtn from '../components/ScrollToBtn';
import MainFacilities from '../components/MainFacilities';
import { faArrowsAlt, faBed, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ChildrenNotes from '../components/ChildrenNotes';

const RoomTemplate = ({data}) => {

  const windowSize = useWindowSize(1000)

  const [galleryOpened, setGalleryOpened] = useState(false)

  const room = data.markdownRemark.frontmatter;
  const images = data.markdownRemark.frontmatter.images

  const gallery_pics = images.map(({childImageSharp}) => {
    return {
      photo: childImageSharp.fluid.srcWebp
    }
  })

  return (
    <Layout
      sidebarComponent={<RelatedRooms active_id={data.markdownRemark.id} />}
      className="detail-page room-page"
      sidebarTriggerTitle="Other Rooms & Suites"
    >
      <SEO title={data.markdownRemark.frontmatter.title + ' | ' + 'Booking'}/>

      {
        (windowSize.windowWidth > 1000) &&
        <section className="row-2-cols">
          <div className="one-half" onClick={() => setGalleryOpened(true) }>
            <BgImage className='detail-pic detail-pic-big' image={images[0]} />
          </div>
    
          <div className="one-half">
            <div onClick={() => setGalleryOpened(true) }>
              <BgImage className='detail-pic' image={images[1]} />
            </div>
            <div className="row-2-cols">
              <div className="one-half" onClick={() => setGalleryOpened(true) }>
                <BgImage className='detail-pic' image={images[2]} />
              </div>
              <div className="one-half last-pic" onClick={() => setGalleryOpened(true) }>
                <BgImage className='detail-pic' image={images[3]} />
                {
                  images.length > 4 &&
                  <div className="more-pics">
                    <span>+ { images.length - 4 } pictures</span>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      }

      {
        (windowSize.windowWidth <= 1000) &&
        <Slider dots={false} slidesToScroll={1} slidesToShow={1} className="detail-slider" dots={true}>
          {images.map((slide) => (
            <div onClick={() => setGalleryOpened(true) } key={uuid.v4()}>
              <BgImage
                className='detail-pic'
                image={slide}
              />
            </div>
          ))}
        </Slider>  
      }
      
      <div className="detail-page-header">
        <div className="row">
          <div className="col-md-6">
            <h1>{room.title} - <span>{data.markdownRemark.frontmatter.price}<span> / Night</span></span></h1>
            <div className="room-main-meta">
              <ul>
                <li title="Sleeps"><FontAwesomeIcon icon={faBed} /> {data.markdownRemark.frontmatter.beds}</li>  
                <li title="Surface"><FontAwesomeIcon icon={faArrowsAlt} /> 30 m²</li>  
              </ul>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <ScrollToBtn
              title={<>Booking a Luxury Room <FontAwesomeIcon icon={faCalendarAlt} /></>}
              className="booking-btn" 
              destination=".booking-form-wrapper"
              overflowedDiv=".page-content"
            />
          </div>        
        </div>
        <div className="similar-rooms-note">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Riad Chebbi has 4 other similar suites in different colors (Green, Red, Brown and Orange)
        </div>
      </div>

      
      <div className="inner-content">
        
        

        <div className="room-content-block">
          <h2 className="room-sub-title">Room amenities</h2>
          <ul className="room-amenities">
            <li>✓ Patio</li>
            <li>✓ Safety Deposit Box</li>
            <li>✓ Air conditioning</li>
            <li>✓ Seating Area</li>
            <li>✓ Heating</li>
            <li>✓ Dressing Room</li>
            <li>✓ Sofa</li>
            <li>✓ Soundproofing</li>
            <li>✓ Tile/Marble floor</li>
            <li>✓ Private pool</li>
            <li>✓ Wardrobe or closet</li>
            <li>✓ Cleaning products</li>
            <li>✓ Minibar</li>
            <li>✓ Refrigerator</li>
            <li>✓ Dining area</li>
            <li>✓ Dining table</li>
            <li>✓ Outdoor furniture</li>
            <li>✓ Outdoor dining area</li>
            <li>✓ Wake-up service</li>
            <li>✓ Executive Lounge Access</li>
            <li>✓ Bed sheets</li>
            <li>✓ Board games/puzzles</li>
            <li>✓ Books, DVDs, or music for children</li>
          </ul>
        </div>


        <div className="room-content-block">
          <h2 className="room-sub-title">Private bathroom amenities</h2>
          <ul className="room-amenities">
            <li>✓ Hairdryer</li>
            <li>✓ Free toiletries</li>
            <li>✓ Slippers</li>
            <li>✓ Bath or Shower</li>
            <li>✓ Towels</li>
            <li>✓ Lowered sink</li>
          </ul>
        </div>

        <ChildrenNotes />

        <MainFacilities />

      </div>


      <div className="inner-content booking-form-wrapper block-bg-img">
        <BookingForm
          hideProductType={true}
          productType="Luxury room"
        />
      </div>

      <ReactBnbGallery
        show={galleryOpened}
        photos={gallery_pics}
        onClose={() => setGalleryOpened(false)}
      />

    </Layout>
  )

}

export default RoomTemplate

export const RoomPageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      frontmatter {
        title
        type
        path
        date
        price
        beds
        images {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

